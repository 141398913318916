// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-negroni-science-js": () => import("./../../../src/pages/negroni-science.js" /* webpackChunkName: "component---src-pages-negroni-science-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */)
}

